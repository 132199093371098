<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-card-header>
        <span> <i class="icon-list"></i> Lista de Clientes </span>
        <b-button size="sm" variant="primary" @click="abrirCadastroClientes()" class="float-right">
          <i class="fa fa-plus"></i> Novo Cliente
        </b-button>
      </b-card-header>
      <b-card-body>
        <b-container>
          <i style="display:none;" @click="reload" class="fa fa-times fa-lg icone-x" id="icone-x"></i>
          <my-data-table
            id="clientsTable"
            :search="this.searchResult.search"
            :fields="this.searchResult.fields"
            :hiddenFields="hiddenFields"
            :sortColumn="this.searchResult.sortColumn"
            :sortDirection="this.searchResult.sortDirection"
            :totalResults="this.searchResult.totalResults"
            :results="this.searchResult.results"
            :resultsPerPage="this.searchResult.resultsPerPage"
            :totalPages="this.searchResult.totalPages"
            :page="this.searchResult.page"
            :pages="this.searchResult.pages"
            :buttons="'end'"
            class="font-12"
            v-on:doSearch="loadClients"
          >
            <template v-slot:default="value">
              {{ value.propertyValue | format(value.propertyName) }}
            </template>

            <template v-slot:status="value">
              <cliente-status :status="value.propertyValue" />
            </template>
            
            <template #buttons="value">
              <b-button
                class="my-data-table-row-button"
                size="sm"
                variant="success"
                v-show="value.row.status != 'R'"
                v-on:click="openClient(value.row)"
              >
                <i class="fa fa-search-plus"></i>
              </b-button>
              <b-button v-bind:id="value.row.codigo" variant="info" size="sm" v-show="value.row.status == 'R'">
                <i class="fa fa-search-plus"></i>
              </b-button>
              <b-popover :target="value.row.codigo.toString()" variant="info" triggers="click" v-if="value.row.observacao">
                {{value.row.observacao}}
              </b-popover>
            </template>
            <template #buttonsCaption>Ações</template>
          </my-data-table>
        </b-container>
      </b-card-body>
    </b-card>
  </div>
</template>

<style scoped>
 .icone-x {
    position: absolute;
    margin-top: 0.7em;
    margin-left: 52em;
    z-index: 10;
    cursor: pointer;
  }

@media screen and (max-width: 1460px) {
  .icone-x {
    margin-left: 51em;
  }
}

@media screen and (max-width: 1420px) {
  .icone-x {
    margin-left: 52em;
  }
}

@media screen and (max-width: 1400px) {
  .icone-x {
    margin-left: 52em;
  }
}

@media screen and (max-width: 1380px) {
  .icone-x {
    margin-left: 52em;
  }
}

@media screen and (max-width: 1365px) {
  .icone-x {
    margin-left: 52em;
  }
}
</style>

<style scoped>
@media (min-width: 1200px){
  .container {
    max-width: 1212px;
  }
 }

.font-12{
  font-size: 12px;
}

.estilo-novo{
  color: #fff !important;
  text-decoration: none !important;
}

.width_5{
  width: 5%;
}

#codigoclientsTable{
  width: 7% !important;
}

#ufclientsTable{
  width: 5% !important;
}

#statusclientsTable{
  width: 6% !important;
}

#razao_socialclientsTable{
  width: 30% !important;
}

#clienteclientsTable{
  width: 30% !important;
}

#cnpjclientsTable{
  width: 12% !important;
}

#cidadeclientsTable{
  width: 13% !important;
}

#data_ultima_vendaclientsTable{
  width: 10% !important;
}

#ramo_atividadeclientsTable{
  width: 15% !important;
}
</style>

<script>
import Revenda from "../../../services/revenda";
import MyDataTable from "@/components/ui/MyDataTable";
import ClienteStatus from "./ClienteStatus";
import Vue from "vue";

export default {
  name: "Clientes",
  components: { MyDataTable, ClienteStatus },
  data() {
    return {
      searchResult: [],
      showDismissibleAlert: false,
      hiddenFields: ["observacao"]
    };
  },
  computed: {
    gerarLinkCadastroCentralizado() {
      return () => {
        let ref = {id: this.$store.state.login.user.codigo, 
                   codigo: this.$store.state.login.resale.revenda_codigo, 
                   nome: this.$store.state.login.user.first_name, 
                   tp_usuario: "integrador"};
                   
        ref = window.btoa(JSON.stringify(ref));

        return `${process.env.VUE_APP_CADASTRO}/cadastro/direct?ref=${ref}`;
      };
    }
  },

  beforeMount() {
    let params = {
      "params": {
        "resultsPerPage":20
      }
    };
    this.loadClients(params);
  },
  methods: {
    loadClients(data = null) {
      let icone    = document.getElementById('icone-x');
      let pesquisa = document.getElementById('pesquisa-input');

      if(pesquisa && pesquisa.value != "")
      {
        icone.style.display="block";
      }else if(pesquisa)
      {
        icone.style.display="none"
      }
      Revenda.getClientes(data)
        .then(response => {
          this.searchResult = response.data.data;
        })
        .catch(e => {
          if (
            e.response &&
            e.response.status &&
            e.response.status === 404 &&
            e.response.data
          ) {
            this.searchResult = e.response.data.data;
          }
        });
    },
    openClient(row) {
      if(row.status == "P"){
        this.$helper.showMsg("Cadastro em Análise", 'warning');
      } else {
        this.$router.push("clientes/cliente/" + row.codigo);
      }
    },
    reload()
    {
      location.reload();
    },
    abrirCadastroClientes(){
      window.open(this.gerarLinkCadastroCentralizado());
    }
  },
  filters: {
    format(value, field) {
      switch (field) {
        case "cidade":
        case "razao_social":
          return Vue.filter("toUpper")(value);
        case "data_ultima_venda":
          return Vue.filter("formatDate")(value);
        default:
          return value;
      }
    }
  }
};
</script>
